.rjsf .MuiTypography-body1,
.rjsf .MuiInputBase-input {
	font-size: 0.875rem !important;
}

.rjsf .MuiGrid-item h5 {
	font-size: 20px;
}

.amplify-form-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 100px;
}